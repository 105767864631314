import React from 'react'
import Jumbotron from '../Jumbotron'
import About from '../About'
import Section1 from '../About/Section1'
import Section2 from '../About/Section2'
import Section3 from '../About/Section3'
import { Footer } from '../Footer'
import { section_id } from '../../../utils/constants'

function Home() {
  return (
    <div className='main-container'>
      <Jumbotron />
      <About title='Our' titleAccent='Story' ident={section_id.section1}>
        <Section1 />
      </About>
      <About title='Our' titleAccent='Services' ident={section_id.section2}>
        <Section2 />
      </About>
      <About
        title="Let's"
        titleAccent='Work Together'
        ident={section_id.section3}
      >
        <Section3 />
      </About>
      <Footer />
    </div>
  )
}

export default Home
