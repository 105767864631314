import React from 'react'
import App from './App'
import Home from './App/components/Home'
import Section1 from './App/components/About/Section1'
import Section2 from './App/components/About/Section2'
import Section3 from './App/components/About/Section3'
import { section_id } from './utils/constants'

import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from 'react-router-dom'

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<App />}>
        <Route index element={<Home />} />
        {/* <Route path='home' element={<Home />} /> */}
        <Route path='home' element={<Home />}>
          <Route path={section_id.section1} element={<Section1 />} />
          <Route path={section_id.section2} element={<Section2 />} />
          <Route path={section_id.section3} element={<Section3 />} />
        </Route>
        {/* <Route path='resume' element={<Resume />} /> */}
      </Route>
    </>,
  ),
)
