import React from 'react'
import { IconsInfoObject } from '../../hooks/useIcons'

export default function Icons({
  size = 50,
  descShowFlag = true,
  iconNames = Object.keys(IconsInfoObject),
}) {
  const iconsComponentArray = iconNames.map((key, index) => {
    const {
      slug: IconComponent,
      color,
      text,
      link,
    } = IconsInfoObject[key] || IconsInfoObject['html']
    if (link.length > 0) {
      return (
        <>
          <a href={link} target='_blank'>
            <IconComponent
              key={index}
              size={size}
              onMouseEnter={() => {
                document.querySelector('svg').style.color = color
              }}
            />
            {descShowFlag && <span className='skill-name'>{text}</span>}
          </a>
        </>
      )
    } else {
      return (
        <>
          <IconComponent key={index} size={size} color={color} />
          {descShowFlag && <span className='skill-name'>{text}</span>}
        </>
      )
    }
  })
  return <>{iconsComponentArray}</>
}
