import React from 'react'
import { Formik } from 'formik'
import { Box, FormField, TextInput, Button, TextArea, Form } from 'grommet'
import { sendEmailJS } from '../../../utils'
// Function called on submit that uses emailjs to send email of valid contact form
const customSubmit = async (data, resetfunc) => {
  const { subject, email, comments } = data
  try {
    const templateParams = {
      email,
      subject,
      comments,
    }
    await sendEmailJS(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      process.env.REACT_APP_PUBLIC_EMAILJS,
      templateParams,
    )
    resetfunc()
  } catch (e) {
    console.log(e)
    alert('error submitting form, please refresh and try again')
  }
}

function ContactForm(props) {
  // const [value, setValue] = React.useState({})
  // const [submitted, setSubmitted] = React.useState(false)

  return (
    <Box className='form_container'>
      <Formik
        initialValues={{}}
        validate={values => {
          const errors = {}
          if (!values.subject) {
            errors.subject = 'required'
          }
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }
          return errors
        }}
        // validateOnBlur={true}
        // validateOnChange={true}
      >
        {({ values, errors, handleChange, handleSubmit, resetForm }) => (
          <Form
            onSubmit={() =>
              customSubmit(values, () => {
                resetForm()
                props.toastifySuccess('Form Sent!')
              })
            }
          >
            <Box direction='row' gap='medium' className='name_email'>
              <FormField label='Subject' error={errors.subject}>
                <TextInput
                  name='subject'
                  className='form_input'
                  placeholder='subject'
                  value={values.subject || ''}
                  onChange={handleChange}
                />
              </FormField>
              <FormField label='Email' error={errors.email}>
                <TextInput
                  name='email'
                  className='form_input'
                  type='email'
                  placeholder='your email here'
                  value={values.email || ''}
                  onChange={handleChange}
                />
              </FormField>
            </Box>
            <FormField label='body' error={errors.comments}>
              <TextArea
                className='form_input'
                placeholder="Your message here. We're looking forward to hearing from you!"
                name='comments'
                value={values.comments || ''}
                onChange={handleChange}
              />
            </FormField>
            <Box direction='row' gap='medium' className='form_buttons'>
              <Button
                className='grommit_button primary'
                type='submit'
                primary
                label='Submit'
                id='submit_btn'
              />
              <Button
                className='grommit_button secondary'
                type='reset'
                label='Reset'
                onClick={() => resetForm()}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default ContactForm
