import { useState, useEffect } from 'react'
const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false)
  console.log('element', element, element.type)
  useEffect(() => {
    if (element.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setState(entry.isIntersecting)
        },
        { rootMargin },
      )
      element.current && observer.observe(element.current)
      return () => {
        observer.unobserve(element.current)
      }
    } else {
      return () => console.warn('error catching element', element)
    }
  })

  return isVisible
}
export default useIntersection
