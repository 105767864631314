import React from 'react'
import Lottie from 'react-lottie'
import animationData from './services.json'

function Section2_Lottie(props) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    renderer: 'svg',
  }

  const style = {
    width: '84rem',
    height: '45rem',
    position: 'relative',
    bottom: '21rem',
    right: '8rem',
  }

  return <Lottie options={defaultOptions} className='lottie' style={style} />
}
export default Section2_Lottie
