import React, { useContext, useEffect } from 'react'
import { Grommet } from 'grommet'
import { ToastContainer } from 'react-toastify'
import ContactForm from '../../components/Contact/basicContact'
import {
  grommet_theme_light,
  grommet_theme_dark,
} from '../../../utils/constants'
import { modeContext } from '../../../providers/ModeProvider'
import { toastifySuccess } from '../../../utils'

function Section3() {
  const { modeStyle } = useContext(modeContext)
  const getTheme = () =>
    modeStyle == 'dark' ? grommet_theme_dark : grommet_theme_light
  return (
    <div className='section-3' id='section_3_grommet'>
      <Grommet
        cssVars
        containerTarget={document.getElementById('section_3_grommet')}
        theme={getTheme(modeStyle)}
        className='grommet-theme'
      >
        <ContactForm toastifySuccess={toastifySuccess} />
      </Grommet>
      <ToastContainer />
    </div>
  )
}

export default Section3
