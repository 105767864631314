import './About.scss'
import React, { useEffect } from 'react'
import { pageAnimation } from '../../../utils'

function About(props) {
  useEffect(() => {
    pageAnimation('about')
  }, [])

  return (
    <section id={props.ident} className='about'>
      <div className='section-title'>
        <h2>{props.title}</h2>
        <h2 className='accent'>{props.titleAccent}</h2>
      </div>
      {props.children}
    </section>
  )
}

export default About
