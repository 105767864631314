// import picture from "../images/picture.jpg";
import { Box, Grommet, WorldMap } from 'grommet'

import React, { useContext, useState } from 'react'
// import picture from '../../../assets/page2.avif'
import { fadeOut } from '../../../utils'
import { modeContext } from '../../../providers/ModeProvider'
import {
  grommet_theme_light,
  grommet_theme_dark,
} from '../../../utils/constants'

function Section1() {
  const [readMore, setReadMore] = useState(false)
  const { modeStyle } = useContext(modeContext)
  const getTheme = mode =>
    modeStyle == 'dark' ? grommet_theme_dark : grommet_theme_light
  // TODO change the animation for read more
  // useEffect(() => {
  //   if
  // }, [readMore]);
  const clickedMore = () => {
    fadeOut('#read-more-clicker')
    setTimeout(() => {
      setReadMore(true)
    }, 700)
  }

  const extendedAbout = (
    <div className='read-more'>
      <p>
        As a software engineer, I always had a passion for technology and the
        impact it can have on businesses and society. After years of working for
        various companies, I finally made the decision to start my own IT
        consulting firm during the pandemic. I was young, ambitious and eager to
        make a difference. The pandemic had just hit, and businesses were
        struggling to adapt to the new normal. Remote work and digital
        transformation were becoming more and more important, and I saw an
        opportunity to help companies navigate these challenges.{' '}
      </p>
      <p>
        We started this firm with the goal of delivering innovative technology
        solutions that would help businesses not only survive but thrive in the
        current landscape. The journey was not easy, especially during a
        pandemic. I had to put in long hours and take on multiple projects to
        get the business off the ground. There were times when I felt
        overwhelmed and questioned my decision, but I was driven by my passion
        and the desire to succeed.
      </p>
      <p>
        One of our first projects was for a small retail business that was
        struggling to keep up with online sales. I helped them revamp their
        website and implement a new e-commerce platform , and they quickly saw a
        surge in sales and a significant increase in online traffic. This was a
        defining moment for us, as we realized the impact that our skills and
        expertise could have on people's lives and businesses. As the business
        grew, We hired a team of talented individuals who shared a common
        passion for technology and had a desire to make a difference. Together,
        we continued to deliver innovative solutions and help businesses stay
        ahead of the curve in a rapidly changing world. Looking back, starting
        <span> ARMAT IND </span>
        during the pandemic was one of the best decisions we ever made. It was a
        challenging journey, but it taught me the importance of perseverance,
        hard work, and the power of technology to make a positive impact. I am
        proud of what I have accomplished and I am excited for what the future
        holds for this business and the people we serve.
      </p>
    </div>
  )

  const renderMore = bool => {
    if (bool) {
      return extendedAbout
    }
    return (
      <div className='clicker-container' id='read-more-clicker'>
        <div className='arrow-clicker' onClick={() => clickedMore()}>
          <p>Learn More</p>
          <i className='down-arrow'></i>
        </div>
      </div>
    )
  }

  return (
    <div className={`section-1${readMore ? ' extended' : ''}`}>
      <div className='img-container' id='about_section_img_container'>
        <Grommet
          containerTarget={document.getElementById(
            'about_section_img_container',
          )}
          // defaultProps={{ ...defaultProps, [defaultProps.theme]: theme }}
          theme={getTheme(modeStyle)}
          // themeMode={modeStyle == 'dark' ? 'dark' : 'light'}
          className='grommet-theme'
        >
          <Box direction='row' justify='between'>
            <WorldMap
              id='world-map'
              color='textColor'
              continents={[
                {
                  name: 'North America',
                  color: 'brandColor',
                  // onClick: name => {},
                },
              ]}
              onSelectPlace={(lat, lon) => {}}
              places={[
                {
                  name: 'NYC',
                  location: [39.5, -80],
                  color: 'accentTwo',
                  // onClick: name => {},
                },
              ]}
              selectColor='brandColor'
            />
          </Box>
        </Grommet>
      </div>
      <div className='section-1-text'>
        <p>
          we are a company founded during the pandemic, based out of New York,
          with the goal of helping businesses navigate the economic uncertainty
          caused by the pandemic.
        </p>
        {renderMore(readMore)}
      </div>
    </div>
  )
}

export default Section1
