import React from 'react'
import LogoLight from '../../../assets/Black_and_Blue_Animated_Logo.png'
import logoDark from '../../../assets/Black_and_Brown_Animated_Logo.png'

function Logo(props) {
  if (props.type === 'light') {
    return (
      <div id='logo'>
        <img src={LogoLight} alt='ARMAT IND' />
      </div>
    )
  } else {
    return (
      <div id='logo'>
        <img src={logoDark} alt='ARMAT IND' />
      </div>
    )
  }
}

export default Logo
