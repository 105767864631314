import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import classNames from 'classnames'
import Navbar from 'react-bootstrap/Navbar'
import DarkMode from '../DarkMode'
import { modeContext } from '../../../providers/ModeProvider'
import Logo from './Logo'
import { handleScroll } from '../../../utils'
import { section_id } from '../../../utils/constants'
// import Resume from "../Resume";
// import pdf from "../../documents/Resume.pdf";
import 'bootstrap/dist/css/bootstrap.min.css'
import './Navigation.scss'

function Navigation() {
  const { modeStyle } = useContext(modeContext)
  const [menuIcon, setMenuIcon] = useState('menu')
  const linkStyle = classNames(modeStyle)

  const handleClick = () => {
    menuIcon === 'menu' ? setMenuIcon('close') : setMenuIcon('menu')
  }

  return (
    <>
      <Navbar
        className={classNames('navigation', modeStyle)}
        collapseOnSelect
        expand='sm'
      >
        <Link className={linkStyle} to='/'>
          <Logo type={linkStyle} />
        </Link>
        <DarkMode />
        <Navbar.Toggle aria-controls='nav'>
          <span
            className='material-symbols-rounded'
            onClick={() => handleClick()}
          >
            {menuIcon}
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse className='justify-content-end' id='nav'>
          <Nav>
            <Link
              className={linkStyle}
              onClick={() => handleScroll(section_id.section1)}
              to={`/home/${section_id.section1}`}
            >
              Profile
            </Link>
            <Link
              className={linkStyle}
              onClick={() => handleScroll(section_id.section2)}
              to={`/home/${section_id.section2}`}
            >
              Services
            </Link>
            <Link
              className={linkStyle}
              onClick={() => handleScroll(section_id.section3)}
              to={`/home/${section_id.section3}`}
            >
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Navigation
