import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Icons from '../Icons'

import {
  grommet_theme_light,
  grommet_theme_dark,
} from '../../../utils/constants'
import { modeContext } from '../../../providers/ModeProvider'
import { section_id } from '../../../utils/constants'
import { handleScroll } from '../../../utils'
import Logo from '../Navigation/Logo'

function GrommitFooter(props) {
  const { modeStyle } = React.useContext(modeContext)
  const linkStyle = classNames(modeStyle)
  const getIcons = () => {
    const icons = ['ig', 'yt', 'tw', 'fb']
    const iconsArray = (
      <Icons size={30} iconNames={icons} descShowFlag={false} />
    )
    return iconsArray
  }
  const getTheme = () =>
    modeStyle == 'dark' ? grommet_theme_dark : grommet_theme_light

  return (
    <div id='footer'>
      <footer className='footer-distributed'>
        <div className='footer-left'>
          <Link className={linkStyle} to='/'>
            <Logo type={linkStyle} />
          </Link>
          <p className='footer-links'>
            <Link
              className={linkStyle}
              onClick={() => handleScroll(section_id.section1)}
              to={`/home/${section_id.section1}`}
            >
              Profile
            </Link>
            <Link
              className={linkStyle}
              onClick={() => handleScroll(section_id.section2)}
              to={`/home/${section_id.section2}`}
            >
              Services
            </Link>
            <Link
              className={linkStyle}
              onClick={() => handleScroll(section_id.section3)}
              to={`/home/${section_id.section3}`}
            >
              Contact
            </Link>
          </p>

          <p className='footer-company-name'>
            ARMAT IND © 2023 Developed By in-house Eng team
          </p>
        </div>

        <div className='footer-center'>
          <div>
            <i className='fa fa-map-marker'></i>
            <p>
              <span>1855 Broad St</span> New York NY
            </p>
          </div>
          <div>
            <i className='fa fa-map-marker'></i>
            <p>
              <span>30 N GOULD ST STE 29268 </span> SHERIDAN, WY
            </p>
          </div>
          <div>
            <i className='fa fa-envelope'></i>
            <p>
              <a href='mailto:sales@armat.biz?subject=would love to work with you!'>
                sales@armat.biz
              </a>
            </p>
          </div>
        </div>

        <div className='footer-right'>
          <h3>Reach out!</h3>
          <p className='footer-company-about'>
            Also, feel free to follow us on Social Media to help support and
            stay up to date with our latest projects!
          </p>

          <div className='footer-icons'>{getIcons()}</div>
        </div>
      </footer>
    </div>
  )
}

export default GrommitFooter
