import emailjs from 'emailjs-com'

export default async function sendEmailJS(
  serviceID,
  templateID,
  userID,
  templateParams,
) {
  emailjs.send(serviceID, templateID, templateParams, userID).then(
    function (response) {
      console.log('SUCCESS!', response.status, response.text)
    },
    function (error) {
      console.log('FAILED...', error)
    },
  )
}
