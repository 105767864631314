import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const splitText = (selector) => {
    const elem = document.querySelector(selector);
    const text = elem.innerText;
    const chars = text.split("");
    // console.log('cars', chars);
    const charsContainer = document.createElement("div");
    const charsArray = [];
  
    charsContainer.style.position = "relative";
    charsContainer.style.display = "inline-block";
  
    chars.forEach((char) => {
    //   if (char === ' ') {
    //     char = '';
    //   }
      const charContainer = document.createElement("div");
  
      charContainer.style.position = "relative";
      charContainer.style.display = "inline-block";
      charContainer.innerText = char;
      charsContainer.appendChild(charContainer);
  
      charsArray.push(charContainer);
    });
    // remove current text
    elem.innerHTML = "";
    // append new structure
    elem.appendChild(charsContainer);
  
    return charsArray;
  };

export const animateByChars = function (selector) {
    const chars = splitText(selector);
    return gsap.from(chars, {
      duration: 0.2,
      y: 100,
      opacity: 0,
      stagger: 0.1,
      delay: 1
    });
};

export const fadeOut = function (selector) {
  const tl = gsap.timeline({ 
    paused: true, 
    defaults: { duration: 1 }
  })
  .to(selector, { opacity: 0 })

  tl.restart();
  };

export const fadeIn = function (selector) {
  const tl = gsap.timeline({ 
    paused: true, 
    defaults: { duration: 1 }
  })
  .to(selector, { opacity: 0 })

  tl.restart();
  };

export const pageAnimation = function(selector) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.defaults({ ease: "back" });
  gsap.set(selector, { y: 100 });

  ScrollTrigger.batch(selector, {
    onEnter: (batch) =>
      gsap.to(batch, {
        opacity: 0.8,
        y: 0,
        stagger: { each: 0.15, grid: [1, 2] },
        overwrite: true,
      }),
    onLeave: (batch) =>
      gsap.set(batch, {
        opacity: 0,
        y: -100,
        overwrite: true,
      }),
    onEnterBack: (batch) =>
      gsap.to(batch, {
        opacity: 0.8,
        y: 0,
        stagger: 0.15,
        overwrite: true,
      }),
    onLeaveBack: (batch) =>
      gsap.set(batch, {
        opacity: 0,
        y: 100,
        overwrite: true,
      }),
  });

  ScrollTrigger.addEventListener("refreshInit", () =>
    gsap.set(selector, { y: 50 })
  );
}

export const handleScroll = (selector) => {
  const element = document.getElementById(selector);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};