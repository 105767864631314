import React, { useEffect, useRef, useState } from 'react'
import { pageAnimation, Section2_Lottie } from '../../../utils'
import useIntersection from '../../../App/hooks/useIntersection'

// import picture from "../../../assets/services.json";

function Section2() {
  const ref = useRef()
  const inViewport = useIntersection(ref, '0px')
  // Only trigger after this component has loaded.
  // useEffect(() => {
  //   pageAnimation('.');
  // }, []);

  const getLottie = () => {
    if (inViewport) {
      console.log('in view port', inViewport)
      return <Section2_Lottie />
    } else {
      return null
    }
  }

  return (
    <div className='section-2' ref={ref}>
      <div className='section-2-pretty'>{getLottie()}</div>
      <div className={`section-2-text${inViewport ? ' lottie-formatter' : ''}`}>
        <div className='bullet'>
          <h1>
            <span className='accent'>1</span>Tech Enablement
          </h1>
          <p>
            We house a dedicated IT consulting staff on-site and on-shore, ready
            to support all your technology needs.
          </p>
        </div>
        <div className='bullet'>
          <h1>
            <span className='accent'>2</span>Rental Arbitrage
          </h1>
          <p>
            We will lease your property - enable it with state of the art tech,
            and market it to business professionals
          </p>
        </div>
        <div className='bullet'>
          <h1>
            <span className='accent'>3</span>Digital Analytics
          </h1>
          <p>
            We will take control of the campaign process, from designing assets
            to launching a data-driven campaign
          </p>
        </div>
      </div>
    </div>
  )
}

export default Section2

/**
 * TODO:
 * on highlight for titles
 * side arrow buttons next to each section
 */
