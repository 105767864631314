export const handleScroll = async id => {
  setTimeout(() => {
    const element = document.getElementById(id)

    if (element) {
      console.log(`Found ${id} component.`)
      element.scrollIntoView({ behavior: 'smooth' })
    } else {
      console.log('No luck finding component.')
    }
  }, 100)
}
