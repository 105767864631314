import React, { useContext, useEffect } from 'react'
import './Jumbotron.scss'
import Animate from './Animate'
import { gsap } from 'gsap'
import { animateByChars, pageAnimation } from '../../../utils'
import { modeContext } from '../../../providers/ModeProvider'

function Jumbotron() {
  useEffect(() => {
    pageAnimation('.intro')
    animateByChars('p')
  }, [])
  const { ballColor } = useContext(modeContext)

  const handleScroll = () => {
    const element = document.getElementById('about')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className='jumbotron'>
      <section className='intro'>
        <div className='title'>
          <h2>ARMAT</h2>
          <h2 className='accent'>INDUSTRIES</h2>
          <p>Where&nbsp;Smart&nbsp;Money&nbsp;Meets&nbsp;Smart&nbsp;Moves</p>
        </div>
        <div className='clicker-container'>
          <div className='arrow-clicker' onClick={() => handleScroll()}>
            <p>About</p>
            <i className='down-arrow'></i>
          </div>
        </div>
      </section>
      {Array(500)
        .fill(true)
        .map((_, i) => (
          <Animate key={i} ballColor={ballColor} />
        ))}
    </div>
  )
}

export default Jumbotron
