import {
  ReactJs,
  Html5,
  CssThree,
  Javascript,
  Nodedotjs,
  Sass,
  Mongodb,
  Postgresql,
  Jquery,
  Bootstrap,
  Rubyonrails,
  Jest,
  Cypress,
  Storybook,
  Redux,
  Express,
  Ruby,
  Postman,
  Googlemaps,
  Canva,
  Git,
  Wix,
  Greensock,
  Facebook,
  Twitter,
  Instagram,
  Youtube,
} from '@icons-pack/react-simple-icons'

export const IconsInfoObject = {
  html: { slug: Html5, text: 'HTML5', color: '#E34F26', link: false },
  css: { slug: CssThree, text: 'CSS3', color: '#1572B6', link: false },
  sass: { slug: Sass, text: 'Sass', color: '#CC6699', link: false },
  jq: { slug: Jquery, text: 'jQuery', color: '#0769AD', link: false },
  js: { slug: Javascript, text: 'JavaScript', color: '#F7DF1E', link: false },
  react: { slug: ReactJs, text: 'React', color: '#61DAFB', link: false },
  redux: { slug: Redux, text: 'Redux', color: '#764ABC', link: false },
  node: { slug: Nodedotjs, text: 'Node.js', color: '#339933', link: false },
  ex: { slug: Express, text: 'ExpressJS', color: '#000000', link: false },
  psql: { slug: Postgresql, text: 'PostgreSQL', color: '#4169E1', link: false },
  mongo: { slug: Mongodb, text: 'MongoDB', color: '#47A248', link: false },
  bootstrap: {
    slug: Bootstrap,
    text: 'Bootstrap',
    color: '#7952B3',
    link: false,
  },
  rubyRails: {
    slug: Rubyonrails,
    text: 'Ruby on Rails',
    color: '#CC0000',
    link: false,
  },
  ruby: { slug: Ruby, text: 'Ruby', color: '#CC342D', link: false },
  postman: { slug: Postman, text: 'Postman', color: '#FF6C37', link: false },
  maps: {
    slug: Googlemaps,
    text: 'Google Maps API',
    color: '#4285F4',
    link: false,
  },
  git: { slug: Git, text: 'Git & GitHub', color: '#F05032', link: false },
  canva: { slug: Canva, text: 'Canva', color: '#00C4CC', link: false },
  wix: { slug: Wix, text: 'WIX CMS', color: '#0C6EFC', link: false },
  jest: { slug: Jest, text: 'Jest', color: '#C21325', link: false },
  cypress: { slug: Cypress, text: 'Cypress', color: '#17202C', link: false },
  storybook: {
    slug: Storybook,
    text: 'Storybook',
    color: '#FF4785',
    link: false,
  },
  greensock: {
    slug: Greensock,
    text: 'Greensock / GSAP',
    color: '#88CE02',
    link: false,
  },
  fb: {
    slug: Facebook,
    text: 'Facebook',
    color: '#3B5998',
    link: 'https://www.facebook.com/armat.ind',
  },
  tw: {
    slug: Twitter,
    text: 'Twitter',
    color: '#1DA1F2',
    link: 'https://twitter.com/armat_ind',
  },
  ig: {
    slug: Instagram,
    text: 'Instagram',
    color: '#E1306C',
    link: 'https://www.instagram.com/armat_ind/',
  },
  yt: {
    slug: Youtube,
    text: 'YouTube',
    color: '#FF0000',
    link: 'https://www.youtube.com/@Armat_Industries',
  },
}
