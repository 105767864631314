import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

export const toastifySuccess = input => {
  toast(input, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: 'submit-feedback success',
    toastId: 'notifyToast',
  })
}
